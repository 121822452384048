.menu {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    border: 2px solid #cccccc;
    height: 100px;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  
  .item {
    padding: 2px 6px;
    cursor: default;
    background-color: #ffffff;
  }
  
  .item-highlighted {
    color: white;
    background-color: #4095bf;
  }
  
  .item-header {
    background-color: #eeeeee;
    color: #454545;
    font-weight: bold;
  }
